import { roles } from '@/enums'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()
  if (
    !user ||
    !user.value ||
    !user.value?.user_metadata.roles?.includes(roles.SYSTEM_ADMIN)
  ) {
    return abortNavigation()
  }
})
